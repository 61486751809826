import React from "react"
import "../../works/cos.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import cos from "../../../../static/work_headers/cos.png"
import Dict from "../../../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import cos1 from "../../../../static/works_perks/cos_1.png"
import cos3 from "../../../../static/works_perks/cos_2.png"
import cos2 from "../../../../static/works_perks/cos_3.png"
import Contact from "../../../components/contact"
import logo from "../../../../static/logos/cos_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Site Web - COS"
      seoDescription="Church of Soho est un collectif d'art basé à Londres. Pour leurs nouveau site, il voulait une monture qui reflete leur esthetique minimale."
    >
      <div id="works-cos-background">
        <WorkHeader
          title={dict.getTrans("work_cos_title")}
          subTitle={dict.getTrans("work_cos_subtitle")}
          pic={cos}
          navLang={lang}
          hl="#B4B4B4"
          pageUrl="/works/cos"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("cos_about_title")}
        p1={dict.getTrans("cos_about_p1")}
        p2={dict.getTrans("cos_about_p2")}
        logo={logo}
      />
      <div id="works-cos-perks-holder">
        <Perk
          title={dict.getTrans("cos_perk1_title")}
          image={cos1}
          text={dict.getTrans("cos_perk1_text")}
        />
        <Perk
          title={dict.getTrans("cos_perk2_title")}
          image={cos2}
          text={dict.getTrans("cos_perk2_text")}
        />
        <Perk
          title={dict.getTrans("cos_perk3_title")}
          image={cos3}
          text={dict.getTrans("cos_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
